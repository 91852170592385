// axios
import axios from "axios";

var domain = "https://server1.01skills.com/backend/api";

export default axios.create({
  baseURL: domain
  // You can add your headers here
  // headers: {
  //   Authorization: localStorage.getItem("AccessToken")
  // }
});
